<template>
    <div class="login-wrap">
       <el-container>
           <el-main>
        <el-row type="flex"  class="row-bg" justify="space-between">
            <el-col :span="3"><div  class="toprow-left" @click='to_control_login'><el-link style='font-size:15px'>控制台<i class="el-icon-user el-icon--right"></i> </el-link></div></el-col>
            <el-col :span="3"><div  class="toprow-right" @click='to_manual_bind'><el-link style='font-size:15px'>手动绑定<i class="el-icon-s-custom el-icon--right"></i> </el-link></div></el-col>
        </el-row>
        <el-row>
          <el-col :span="24" align="center">
              <div class="title">思迅软件供应链系统</div>
              
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" align="middle">
              <div  >
                  <div  style="paddind-top:10px; width:80%">
                       <el-form  class="form-body" v-show='showiput'  :model="param" :rules="rules" ref="login" label-width="80px" >
                <el-form-item prop="请输入手机号" label="手机号">
                    <el-input v-model="param.tel" placeholder="tel">

                    </el-input>
                </el-form-item>
                <el-form-item prop="password" label="密 码" >
                    <el-input
                            type="password"
                            placeholder="请输入密码"
                            v-model="param.password"
                            @keyup.enter.native="submitForm()"
                    >

                    </el-input>
                </el-form-item>
                <el-form-item prop="sidentify" label="验证码">
                    <el-col :span="12">
                        <el-input placeholder="sidentify" v-model="param.verify" @keyup.enter.native="submitForm()">
                        </el-input>
                    </el-col>
                    <el-col :span="5">
                        <div class="code" @click="refreshCode">
                        <Sidentify :identifyCode="identifyCode"></Sidentify>
                        </div>
                    </el-col>
                </el-form-item>

                <div class="login-btn">
                    <el-button type="primary" @click="submitForm()">登录</el-button>
                    
                </div>
                 <div class="login-btn">
                   
                </div>
            </el-form>    
             <div v-show='showewm'> 
                     <wxlogin :theme="'black'"
                   :appid="appid"
                   :scope="'snsapi_login'"
                   :redirect_uri='encodeURIComponent(reip+"/select_mart")'
                   state="supcust"
                   ></wxlogin> 
             </div> 
            <!-- :redirect_uri='encodeURIComponent(reip+"/select_mart")' -->
            <div ><el-link style='font-size:20px;' type="success" v-show='showewm' @click='changeModel' >手机号登入</el-link></div>.
            <div ><el-link style='font-size:20px;' type="success" v-show='showiput' @click='changeModel' >微信二维码登入</el-link></div>
        </div>

             </div>
          </el-col>
        </el-row>
           </el-main>
         <el-footer ><div>
             Copyright @ 2020-2021 福州通和信计算机有限公司
         </div>
             <div>网站备案号：<el-link href="https://beian.miit.gov.cn/" >闽ICP备18022481号-2</el-link></div></el-footer>
       </el-container>   
    </div>
    
</template>

<script>
import  md5 from '../components/common/js/allSchool.js'
    import Sidentify from '../components/common/identify'  //**引入验证码组件**
    import store  from '../store'
    import axios from 'axios'
    import wxlogin from 'vue-wxlogin'
    import {PostobjKeySort,StrToDate}  from '../components/common/js/printHelp.js'
   
    export default {
        components: {
            Sidentify,
            wxlogin

        },
        data: function() {
            return {
                reip:Gold.baseURLNoApi,
               
              
                showiput:false,
                showewm:true,
                appid:Gold.appid,
                 ip: ''  ,
                identifyCode: '',
                identifyCodes:'1234567890',
                param: {
                    tel: null,
                    password: null,
                    verify:null
                },
                rules: {
                    tel: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
                    password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
                    verify: [{required: true}],
                },
            };
        },

        mounted(){
            this.getIP();
            this.refreshCode();
            console.log("reip",this.reip,this.appid)

            },
        created(){
       //     this.getwxcode()
        },
        methods: {
            to_manual_bind(){
               
                this.$router.push("./manual_bind")},

            to_control_login(){

                this.$router.push("./control_login")},
            changeModel(){
                this.showiput=!this.showiput
                this.showewm=!this.showewm
            },
            ///获取我们的接口服务器返回的是信息
      getwxcode () {
        console.log('created start............................')
      //if (this.$route.query.code)
       {
        this.code = this.$route.query.code
        let params = {}
        params.appid = this.appid
        params.secret = this.secret
        params.code = this.code
        params.grant_type = 'authorization_code'
        getAccessToken(params).then((res) => {
            console.log("这个是   ----------------------                "+res)
          if (res.code === 0) {
            this.$cookie.set('token', res.token)
            this.$router.replace({ name: 'home' })
          } else if (res.code === 500) {
            this.dialogVisible4 = true
            this.unionid = res.unionid
            console.log('Union==')
          } else {
            this.$message({
              message: res.msg,
              type: 'error'
            })
          }
        })
      }

      console.log(this.unionid+'        getwxcode end--------------------------')
    },
        getIP() {
            console.log("glod " ,this.reip)
            let that=this
            md5.cIp(that)
         //  that.md5.commonGetIp(that);
 
             },
            refreshCode(){
                this.identifyCode="";
                this.identifyCode=md5.makeCode(4);

            },
submitForm() {
  //  console.log("parm is " ,this.param)
                const that = this
                if(this.identifyCode==this.param.verify){
                    let query={
                        ip:this.$store.state.ip,
                        mobile:this.param.tel,
                        web_pwd:this.param.password,
                        isadmin:"false"
                        }
                    let newkey = PostobjKeySort(query)                 
                    let sign= md5.M5(newkey.md5s)
                    //this.$axios.post("/SCM/Login?sign="+sign,newkey.par)
                    this.reqM1Service("/SCM/Login?sign="+sign,newkey.par,'post')
                    .then((res => {
                        console.log("使用手机号,得到res",res)
                        let tmp_list=[];

                        if( res.data.code==200) {
                            let v=res.data.data
                            v.forEach(element => {
                          let date1=StrToDate(element.供应链有效期 ) ;
                          let date2=StrToDate(element.供应商有效期) ;
                          let  currentday=new Date();
                          if (date1>=currentday && date2>=currentday)
                          {
                            tmp_list.push(element)
                          }                         
                        }
                        );
                            
                        this.$store.commit("setgyllist",tmp_list)  
                        this.$store.commit("setUserName",tmp_list[0].名字+"/"+tmp_list[0].供应链名称)
                       
                        this.$router.push("./select_mart?state=supcust&code=tel")
                        
               
                        }
                        else{
                            alert("用户或者密码错误!")
                        }
                    })).catch(_ => {
                    });
               }else{
                   alert("验证码不对!")
                   this.refreshCode()
               }
            }
            },
    };
</script>

<style scoped>
    .login-wrap {
        position: relative;
        width: 100%;
        height: 100%;
        background-image: url(../assets/img/backgroup3.jpg);
        
        background-repeat:no-repeat;
        background-size:100% 100%;
      
    }
    .ms-title {
        width: 100%;
        line-height: 50px;
        text-align: center;
        font-size: 24px;
        color:rgb(243, 246, 246);

        padding-top: 200px;
    }
    .ms-login {
        position: absolute;
        left: 50%;
        top: 30%;
        width: 350px;
        margin: -90px 0 0 -175px;
        border-radius: 5px;
        background: rgba(255, 255, 255, 0.3);
        overflow: hidden;
        padding-top: 10px;
    }
    .ms-content {
        padding: 30px 30px;
    }
    .login-btn {
        text-align: center;
    }
    .login-btn button {
        width: 100%;
        height: 36px;
        margin-bottom: 10px;
    }
    .login-tips {
        font-size: 12px;
        line-height: 30px;
        color: #fff;
    }
    .title{

        text-align: center;
        font-size: 40px;
        color:rgb(1, 24, 24);
        margin: 100px 0px 0px 0px ;

    }
    .form-body{
        width: 350px;
        padding: 70px;

        background-image: url(../assets/img/form_back.png);

        background-size:100%;
        padding-top:60px;
        padding-bottom: 20px;
        height:280px;
    }
    .code{
        width: 80%;
    }
    .toprow-left{
        padding-left: 30px;
        padding-top:30px;       
        font-size:30px
    }
     .toprow-right{
        text-align: right;
        padding-top:30px; 
        padding-right: 30px;      
        font-size:30px
    }
      .el-footer {
    background-color: #B3C0D1;
    color: #333;
    text-align: center; 
  }
  .el-container{
      height: 100%;
  }
</style>
